.thank-you-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    color: #616161
}

.error {
    color: red
}
