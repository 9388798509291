.status-container {
  background: #7cd98d;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.status-icon {
  width: 30px;
  height: 30px;
}

.status-text {
  color: #ffffff;
  font-size: 20px;
  margin-left: 20px;
}

.status {
  font-weight: bold;
}

.statistics-container {
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000000;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
}

.statistics-text {
  color: #999999;
  font-size: 20px;
}

.bold {
  font-weight: bold;
}

.push-container{
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
}

.push-required {
  width: 100%
}

.push-button {
  background: #ff6a57;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rec-color {
  background: #fcd442
}

.arrow-icon {
  height: 30px;
  width: 30px;
}

.badge {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  color: #ffffff;
  font-size: 20px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px #8c8c8c solid;
  color: #999999;
  position: fixed;
  bottom: 0;
  height: 70px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
}

.snoozed {
  position: absolute;
  left: -2px;
}
