.header {
    height: 70px!important;
    background-color: #2b7ee3!important;
    align-items: center;
    padding-left: 50px;
}

.header-text {
    color: #ffffff;
    font-size: 20px;
}

.image {
    width: 50px;
    height: 50px;
}

.badge {
    padding: 0px 30px;
}
